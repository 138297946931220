/* NOTE: these field styles are used in every input component that has a label
 for e.g input, select, checkbox group, toggle group, radio group, etc */
.field {
  display: flex;
  flex-direction: column;
  gap: var(--inner-spacing-2);

  /**
  * ----------------------------------------------------------------------------
  * FIELD LABEL
  *-----------------------------------------------------------------------------
  */
  & [data-field-label-wrapper] {
    display: flex;
    align-items: center;
    gap: var(--inner-spacing-1);
    height: var(--sizing-3);
  }

  & [data-field-label] {
    display: flex;
    height: fit-content;
    color: var(--color-fg);
    max-width: 100%;
  }

  & [data-field-label] span {
    webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /**
  * ----------------------------------------------------------------------------
  * REQUIRED ICON
  *-----------------------------------------------------------------------------
  */
  & [data-field-necessity-indicator-icon] {
    width: var(--inner-spacing-2);
    height: var(--inner-spacing-2);
    color: var(--color-fg-negative);
    margin-left: var(--inner-spacing-1);
  }

  /**
  * ----------------------------------------------------------------------------
  * ERROR TEXT
  *-----------------------------------------------------------------------------
  */
  & [data-field-error-text] {
    display: flex;
    align-items: center;
    color: var(--color-fg-negative);
  }

  /**
  * ----------------------------------------------------------------------------
  * FIELD GROUP
  * fields group refers group components like checkbox group, radio group, etc
  *-----------------------------------------------------------------------------
  */
  & [data-field-group] {
    gap: var(--inner-spacing-2);
    display: flex;
    flex-direction: column;
  }

  &[data-disabled] [data-field-group] [data-label] {
    cursor: default;
  }

  & [data-field-group][data-orientation="horizontal"] {
    gap: var(--inner-spacing-4);
    flex-direction: row;
  }

  /**
  * ----------------------------------------------------------------------------
  * FIELD WRAPPER
  *-----------------------------------------------------------------------------
  */
  & [data-field-input-wrapper] {
    gap: var(--inner-spacing-2);
    display: flex;
    flex-direction: column;
  }

  /**
  * ----------------------------------------------------------------------------
  * FIELD INPUT GROUP
  *-----------------------------------------------------------------------------
  */
  & [data-field-input-group] {
    display: flex;
    align-items: center;
  }

  /**
  * ----------------------------------------------------------------------------
  * DISABLED
  *-----------------------------------------------------------------------------
  */
  &[data-disabled] {
    opacity: var(--opacity-disabled);
    cursor: default;
  }
}
